import React, { useCallback, useState, useRef } from 'react'

import { Layout, SEO, EmailForm } from '../components'
import styled from 'styled-components'
import LogoWhite from '../svgs/bbtn-logo-white.svg'
import IntroVideo from '../videos/bbtn-intro-video.mp4'
import { useTransition, animated, useChain, config } from 'react-spring'
import { media, Container } from '@caldera-digital/theme'

import { useScrollLock } from '../hooks/useScrollLock'
import Caret from '../svgs/downArrow.svg'

const HomePageWrapper = styled.div`
  position: relative;

  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;

    ${media.forSmallOnly`
      object-fit: contain;
    `}
  }
`

const HeroContainer = styled.div`
  background-color: ${props => props.theme.homeBackgroundColor};
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const HomeParagraphText = styled.p`
  color: ${props => props.theme.white};
  text-align: center;
  font-weight: 300;
  letter-spacing: -0.43px;
  line-height: 1rem;
`

const IntroCopyContainer = styled.div`
  margin-bottom: 60px;

  ${media.forSmallMediumOnly`
    margin-bottom: 60px;
  `}

  ${media.forSmallOnly`
      margin-bottom: 40px;
  `}
`

const IntroCopy = styled(HomeParagraphText)`
  text-align: center;
  margin-bottom: 0;
`

const WelcomeContentWrapper = styled.div`
  width: 571px;

  > svg {
    width: 100%;
    object-fit: contain;
    margin-bottom: 60px;
  }

  ${media.forSmallMediumOnly`
    > svg {
      margin-bottom: 60px;
    }
  `}

  ${media.forSmallOnly`
    margin: 0 auto;
    width: 100%;
    padding: 0 2rem 0;
    /* Bump up on mobile so learn more visible on Safari */
    margin-top: -6rem;

    > svg {
      display: block;
      margin: 0 auto 1.5rem;
      width: 80%;
      margin-bottom: 20px;
    }
  `}

  @media (max-height: 500px) {
    margin: 0 auto;
    width: 100%;
    padding: 3rem 2rem 0;

    > svg {
      display: block;
      margin: 0 auto 1.5rem;
      width: 80%;
    }
  }
`

const LearnMoreSection = styled.div`
  position: absolute;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${media.forSmallMediumOnly`
    bottom: 70px;
  `}

  ${media.forSmallOnly`
    bottom: 95px;
  `}

  @media (max-height: 500px) {
    margin-top: 4rem;
    padding-bottom: 2rem;
    position: static;
    transform: translateX(0);
  }
`

const DownwardCaretImg = styled(Caret)`
  height: 8px;
  width: 19px;
`

const LearnMoreLink = styled.p`
  color: ${props => props.theme.white} !important;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.43px;
  line-height: 19px;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto 1.25rem;
  display: block;

  ${media.forSmallOnly`
    margin: 0 auto 1.25rem;
  `}
`

const SectionWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  position: relative;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;

  ${media.forSmallMediumOnly`
    justify-content: flex-start;
    margin-top: 4rem;
    margin-bottom: 4rem;
    min-height: auto;
  `}
`

const BigText = styled.p`
  color: #6d7278;
  font-size: 40px;
  line-height: 1.5;
  font-weight: 200;
  text-align: justify;
  border-left: 5px solid ${props => props.theme.secondaryColor};
  padding-left: 1rem;
  margin-bottom: 95px;

  ${media.forSmallMediumOnly`
    border-left: none;
    padding-left: 0;
    margin-left: 0;
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 75px;
  `}

  ${media.forSmallOnly`
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 55px;
    text-align: left;
  `}
`
const BottomCTA = styled.div`
  h2 {
    font-weight: bold;
    font-size: 24px;
  }

  p {
    a {
      text-decoration: underline;
    }
  }

  ${media.forSmallOnly`
    h2 {
      font-size: 20px;
    }
  `}
`

const IndexPage = () => {
  const [showVideo, setShowVideo] = useState(true)

  // Lock scroll on video show
  useScrollLock(showVideo)

  // If the video doesn't finish playing within 15 seconds of the page being rendered, skip it
  setTimeout(() => {
    setShowVideo(false)
  }, 10000)

  const videoTransitionRef = useRef()
  const videoTransition = useTransition(showVideo, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.gentle,
    ref: videoTransitionRef,
  })
  const contentTransitionRef = useRef()
  const contentTransition = useTransition(!showVideo, null, {
    from: { opacity: 0, scale: 0.65 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0 },
    config: config.gentle,
    ref: contentTransitionRef,
  })

  const onVideoEnded = () => setShowVideo(false)

  const playerRef = useRef()
  const onSetVideoRef = useCallback(node => {
    if (!node && playerRef.current) {
      playerRef.current.removeEventListener('ended', onVideoEnded)
    }

    playerRef.current = node
    if (playerRef.current) {
      playerRef.current.addEventListener('ended', onVideoEnded)
    }
  }, [])

  useChain([videoTransitionRef, contentTransitionRef])

  return (
    <Layout fluid showHeader={false}>
      <SEO title="Home" />
      <HomePageWrapper>
        {videoTransition.map(
          ({ item, key, props }) =>
            item && (
              <animated.video
                key={key}
                style={props}
                ref={onSetVideoRef}
                controls={false}
                autoPlay
                muted
                playsInline
              >
                <source src={IntroVideo} type={'video/mp4'} />
              </animated.video>
            ),
        )}

        <HeroContainer>
          {contentTransition.map(
            ({ item, key, props }) =>
              item && (
                <animated.div style={props} key={key}>
                  <WelcomeContentWrapper>
                    <LogoWhite alt="Black by the Numbers logo" />
                    <IntroCopyContainer>
                      <IntroCopy>
                        Our team is hard at work creating something special. In
                        the meantime, connect with us to stay up-to-date on our
                        content launches.
                      </IntroCopy>
                    </IntroCopyContainer>
                    <EmailForm />
                    <LearnMoreSection>
                      <LearnMoreLink>Learn More</LearnMoreLink>
                      <DownwardCaretImg alt={'Down arrow'} />
                    </LearnMoreSection>
                  </WelcomeContentWrapper>
                </animated.div>
              ),
          )}
        </HeroContainer>
        <SectionWrapper>
          <BigText>
            We visualize Black America by sharing Black narratives with artful
            storytelling and thought provoking data visualizations. Our vision
            is to help facilitate a truthful, fact-based narrative about Black
            American life and in the process inspire the next generation of
            change agents.
          </BigText>
          <BottomCTA>
            <h2>Need to get in contact with us?</h2>
            <p>
              Shoot us a note at{' '}
              <a href={'mailto:info@blackbythenumbers.com'}>
                info@blackbythenumbers.com
              </a>
            </p>
          </BottomCTA>
        </SectionWrapper>
      </HomePageWrapper>
    </Layout>
  )
}

export default IndexPage
